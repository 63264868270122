<div class="body_bg">
  <frontend-artist-header></frontend-artist-header>
  <section class="privacy-page contact_us_section">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="title">
            <h1>Kwot Child safety policy</h1>
          </div>
          <div class="content">
            <h3>1. Introduction</h3>
            <p>Kwot is committed to providing a safe and secure environment for all users, including children. As a
              platform hosting video, podcast, and music content, we understand the importance of child safety and
              adhere to strict guidelines to protect young users from harmful or inappropriate content. This policy
              outlines the steps taken by Kwot to comply with relevant regulations, such as the Children's Online
              Privacy Protection Act (COPPA) and the General Data Protection Regulation (GDPR).</p>
            <h3>2. Target Audience</h3>
            <p>Kwot serves users of all ages, but we take extra precautions to ensure that content accessible to
              children under 13 years of age is age-appropriate, and that personal data is handled with the utmost care
              and respect.</p>
            <h3>3. Content Moderation and Filtering</h3>
            <p>To ensure that harmful or inappropriate content does not reach children, we implement the following
              content moderation and filtering practices:</p>
            <ul>
              <li><strong>Human Moderators: </strong>A team of trained moderators reviews all content with special
                emphasis on flagged content for context and appropriateness.</li>
            </ul>
            <h3>4. Restricted Features for Children</h3>
            <p>To ensure child safety, certain features of Kwot will be restricted for users under the age of 13:</p>
            <ul>
              <li><strong>Private Messaging and Direct Communication</strong>Children under 13 are restricted from using
                private messaging or any form of direct communication with other users.</li>
              <li><strong>User-Generated Content: </strong>Children under 13 may only access content created by
                approved, verified users and will not be able to upload or create content without parental consent.</li>
              <li><strong>Personalization and Tracking:</strong>: Personalized ads or tracking based on children’s
                activity is not allowed. Children’s data will not be used for targeted marketing</li>
            </ul>
            <h3>5. Parental Controls and Consent</h3>
            <p>We provide parents and guardians with tools to control their children’s experience on our platform:</p>
            <ul>
              <li><strong>Parental Consent:</strong>Children under 13 are restricted from using private messaging or any
                form of direct communication with other users.</li>
              <li><strong>Restricted Content for Children:</strong>Children under 13 may only access content created by
                approved, verified users and will not be able to upload or create content without parental consent.</li>
            </ul>
            <h3>6. Data Collection and Privacy</h3>
            <p>We are committed to protecting the privacy and safety of children’s personal information:</p>
            <ul>
              <li><strong>Minimal Data Collection: </strong>We limit the collection of personal data from children under
                13 to only what is necessary for the operation of the app.</li>
              <li><strong>COPPA Compliance: </strong>Kwot complies with COPPA by obtaining verifiable parental consent
                before collecting any personal information from children. No personal data is collected or shared with
                third parties without explicit parental consent.</li>
              <li><strong>GDPR Compliance: </strong>For users in the European Union, we adhere to GDPR guidelines by
                ensuring that all children’s data is collected and processed in a lawful, fair, and transparent manner.
              </li>
            </ul>
            <h3>7. Reporting and Response Mechanism</h3>
            <p>We have a robust reporting system in place that allows users to report any concerns regarding child
              safety:</p>
            <ul>
              <li><strong>Content Reporting: </strong>Users can easily report any inappropriate content, interactions,
                or behavior. These reports are reviewed promptly by our moderation team.</li>
              <li><strong>Safety Hotline and Email: </strong>Parents or guardians can contact our safety hotline or
                support team via email to report concerns or seek assistance regarding their child's safety.</li>
              <li><strong>Immediate Action: </strong>: If any content is found to be harmful or a violation of child
                safety standards, it will be removed immediately, and the responsible user may face account suspension
                or banning.</li>
            </ul>
            <h3>8. Educational Resources</h3>
            <p>We are dedicated to educating both children and parents on internet safety and privacy:</p>
            <ul>
              <li><strong>Safety Guidelines: </strong>We provide clear guidelines to children and parents on how to use
                Kwot safely, highlighting the risks of sharing personal information, interacting with strangers, and
                encountering inappropriate content.</li>
              <li><strong>Ongoing Awareness Campaigns: </strong>We regularly update users on safety best practices and
                provide educational resources on how to stay safe while using the platform.</li>
            </ul>
            <h3>9. Compliance with Best Practise Social Mobile App Requirements</h3>
            <p>Kwot complies with best practise social mobile app requirements for child safety, which include:</p>
            <ul>
              <li><strong>App Content Rating:</strong>Our app is rated for social medial best practise rating guidelines
                to ensure age-appropriate content.</li>
              <li><strong>Use of User Data: </strong>We only collect, use, and share data as per best practise policies,
                ensuring that no personal information is shared with third-party advertisers for children under 13.</li>
              <li><strong>Educational Purpose Compliance: </strong>If our app includes educational content for children,
                we ensure it aligns with best practise on educational apps, including providing clear privacy practices
                and safeguarding children’s data.</li>
            </ul>
            <h3>10. Review and Updates</h3>
            <p>This policy will be regularly reviewed and updated to reflect any changes in laws, regulations, or platform features. We are committed to maintaining the highest standards of child safety and compliance with all relevant legislation.</p>
            <h3>Kwot Limited</h3>
            <p><strong>Address:-</strong>32c Cameron Road, Ikoyi Lagos, Nigeria</p>
            <p><strong>Email address for reporting to </strong><a href="mailto:support@kwot.com">support@kwot.com</a></p>
            <p><strong>Phone number is</strong><a href="tel:++234 818 767 4561">+234 818 767 4561</a></p>
              <div class="d-flex">
                  <p>
                      To report a concern related to child safety, please visit
                  </p>
                  <a class="cursor-pointer ms-1" [routerLink]="['/child-safety']"><strong>Child Safety Report</strong></a>.
              </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container footer-section">
      <frontend-artist-footer></frontend-artist-footer>
    </div>
  </section>
</div>
