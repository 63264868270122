import {Component} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {AddReport, getError, getReasons, GetReasons, getSuccess, PublicState} from "@frontend/public-store";
import {select, Store} from "@ngrx/store";
import {Subject, takeUntil} from "rxjs";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'frontend-child-safety-report',
  templateUrl: './child-safety-policy.component.html',
  styleUrls: ['./child-safety-policy.component.scss'],
})
export class ChildSafetyPolicyComponent {
  contactForm: UntypedFormGroup;

  reasonId: any;
  contactData: any;
  reasonIdAdded: any = true;
  isFormSubmitted: any= false;

  contactUs: any[] = [];
  unsubscriber = new Subject();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private publicStore: Store<PublicState>,
    private toastr: ToastrService,
  ) {
    this.publicStore
      .pipe(select(getReasons))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((reasons) => {
        if (reasons) {
          console.log('reasons', reasons)
          this.contactUs = reasons.map((item: any) => ({
            id: item._id,
            name: item.title
          }));
        }
      });

    this.publicStore
      .pipe(select(getSuccess))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((success) => {
        if (success && this.isFormSubmitted) {
          this.toastr.success(success);
          this.contactForm.reset();
          this.reasonId = null;
          this.contactData = null;
          this.isFormSubmitted = false;
        }
      });

    this.publicStore
      .pipe(select(getError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((error) => {
        if (error && this.isFormSubmitted) {
          console.log('success', error)
          this.toastr.error(error);
          this.contactForm.reset();
          this.reasonId = null
          this.contactData = null
          this.isFormSubmitted = false;

        }
      });
  }

  ngOnInit(): void {
    console.log('this.contactData', this.contactUs)
    this.publicStore.dispatch(GetReasons())
    window.scroll(0, 0);
    this.contactForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: [
        '',
        [
          Validators.required,
          Validators.pattern(
            RegExp(/^[a-zA-z0-9._%+-]+@[a-zA-z0-9.-]+\.[a-zA-z]{2,4}$/)
          ),
        ],
      ],
      reason_id: [null],
      message: ['', Validators.required],
    });
  }

  reasonData(id: any) {
    this.reasonId = id.value;
    this.reasonIdAdded = true
  }

  get form() {
    return this.contactForm.controls;
  }

  onSubmitContact() {
    this.reasonIdAdded = !!this.reasonId;
    if (this.contactForm.invalid || !this.reasonId) {
      this.contactForm.markAllAsTouched();

      this.toastr.error('Please fill all the fields');
      return;
    }
    const payload = {
      name: this.contactForm.value.name ? this.contactForm.value.name : '',
      email: this.contactForm.value.email ? this.contactForm.value.email : '',
      reason_id: this.reasonId,
      message: this.contactForm.value.message,
    };

    this.publicStore.dispatch(AddReport({report: payload}))
    this.isFormSubmitted = true;
  }
}
