import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { SupportComponent } from './components/support/support.component';
import { PlansComponent } from './components/plans/plans.component';
import { environment } from '../environments/environment';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import {
  APP_CONFIG,
  HttpAuthInterceptService,
  ToastComponent,
} from '@frontend/app-config';
import { ToastrModule } from 'ngx-toastr';
import { ArtistLandingPageComponent } from './components/artist-landing-page/artist-landing-page.component';
import { ArtistSupportComponent } from './components/artist-support/artist-support.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { PrivacyTermComponent, SharedModule } from '@frontend/shared';
import { HeaderComponent } from './_components/header/header.component';
import { FooterComponent } from './_components/footer/footer.component';
import { RedirectToAppComponent } from './_components/redirect-to-app/redirect-to-app.component';
import { ArtistFooterComponent } from './_components/artist-footer/artist-footer.component';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { BsModalService } from 'ngx-bootstrap/modal';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NoAuthGuard } from './guards/no-auth.guard';

import { ChooseTokenComponent } from './components/choose-token/choose-token.component';
import { JoinLiveStreamComponent } from './components/join-live-stream/join-live-stream.component';
import {PaymentGatwaysModule} from "@frontend/payment-gatways";
import {PublicStoreModule} from "@frontend/public-store";
import {CallbackSigninAppleComponent} from "./components/callback-signin-apple/callback-signin-apple.component";
import { ArtistProfilePageComponent } from './components/artist-profile-page/artist-profile-page.component';
import { PlaylistPageComponent } from './components/playlist-page/playlist-page.component';
import { AlbumPageComponent } from './components/album-page/album-page.component';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import {PodcastPageComponent} from "./components/podcast-page/podcast-page.component";
import {PodcastEpisodePageComponent} from "./components/podcast-episode-page/podcast-episode-page.component";
import {UserPageComponent} from "./components/user-page/user-page.component";
import {RadioPageComponent} from "./components/radio-page/radio-page.component";
import {SkitPageComponent} from "./components/skit-page/skit-page.component";
import {TrackPageComponent} from "./components/track-page/track-page.component";
import {ChildSafetyReportComponent} from "./components/child-safety-report/child-safety-report.component";
import {ChildSafetyPolicyComponent} from "./components/child-safety-policy/child-safety-policy.component";

const routes: Routes = [
  { path: '', component: HomeComponent },
  {
    path: '',
    loadChildren: () =>
      import('@frontend/auth').then((module) => module.AuthModule),
    canActivate: [NoAuthGuard],
  },
  { path: 'support', component: SupportComponent },
  { path: 'plans', component: PlansComponent },
  { path: 'artist', component: ArtistLandingPageComponent },
  { path: 'artist/:id', component: ArtistProfilePageComponent },
  { path: 'child-safety', component: ChildSafetyReportComponent },
  { path: 'child-safety-policy', component: ChildSafetyPolicyComponent },
  { path: 'playlist/:id', component: PlaylistPageComponent },
  { path: 'album/:id', component: AlbumPageComponent },
  { path: 'podcast/:id', component: PodcastPageComponent },
  { path: 'podcast/:id/episode/:id', component: PodcastEpisodePageComponent },
  { path: 'user/:id', component: PodcastEpisodePageComponent },
  { path: 'radio/:id', component: RadioPageComponent },
  { path: 'skit/:id', component: SkitPageComponent },
  { path: 'track/:id', component: TrackPageComponent },
  { path: 'artist-support', component: ArtistSupportComponent },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'privacy-policy', component: PrivacyTermComponent },
  { path: 'terms-conditions', component: PrivacyTermComponent },
  { path: 'join-live-show', component: JoinLiveStreamComponent },
  {
    path: 'purchase-token',
    component: ChooseTokenComponent,
  },
  {
    path: 'callbacks/sign_in_with_apple',
    component: CallbackSigninAppleComponent
  }
];
const fbLoginOptions = {
  scope: 'email, public_profile',
  return_scopes: true,
  enable_profile_selector: true
};
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SupportComponent,
    PlansComponent,
    ToastComponent,
    ArtistLandingPageComponent,
    ArtistProfilePageComponent,
    ChildSafetyReportComponent,
    ChildSafetyPolicyComponent,
    PlaylistPageComponent,
    AlbumPageComponent,
    PodcastPageComponent,
    PodcastEpisodePageComponent,
    UserPageComponent,
    RadioPageComponent,
    SkitPageComponent,
    TrackPageComponent,
    ArtistSupportComponent,
    AboutUsComponent,
    ContactUsComponent,
    HeaderComponent,
    FooterComponent,
    RedirectToAppComponent,
    ArtistFooterComponent,
    ChooseTokenComponent,
    JoinLiveStreamComponent,
    CallbackSigninAppleComponent
  ],
  imports: [
    SharedModule,
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      scrollPositionRestoration: 'top',
    }),
    ToastrModule.forRoot({
      closeButton: true,
      maxOpened: 2,
      autoDismiss: true,
      preventDuplicates: true,
      positionClass: 'toast-top-full-width',
      toastComponent: ToastComponent,
      toastClass: 'ngx-toastr custom-toast',
    }),
    HttpClientModule,
    NgxUsefulSwiperModule,
    FormsModule,
    ReactiveFormsModule,
    NgxIntlTelInputModule,
    TypeaheadModule.forRoot(),
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    PaymentGatwaysModule,
    PublicStoreModule,
  ],
  providers: [
    { provide: APP_CONFIG, useValue: environment },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpAuthInterceptService,
      multi: true,
    },
    BsModalService,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        lang: 'en',
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '312258426675-udgm6rbcc3ap9apihu6svnug22ljsr7d.apps.googleusercontent.com'
            )
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('1729180150888493', fbLoginOptions)
          }
        ],
        onError: (err) => {
          console.error(err);
        }
      } as SocialAuthServiceConfig,
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
