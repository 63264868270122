import { createReducer, on, Action } from '@ngrx/store';
import {
  GetShowDetails,
  GetShowDetailsError, GetShowDetailsSuccess,
  GetTokenDetails,
  GetTokenDetailsError,
  GetTokenDetailsSuccess,
  GenerateRtmToken, GenerateRtmTokenError, GenerateRtmTokenSuccess,
  ResetPublicState, GetArtistDetails, GetArtistDetailsSuccess, GetArtistDetailsError,
  GetReasons,
  GetReasonsSuccess,
  GetReasonsError,
  AddReport,
  AddReportSuccess,
  AddReportError
} from "./public.actions";

export interface PublicState {
  success: string;
  error: string;
  tokenDetails: any;
  showDetails: any;
  rtmToken:any;
  artistDetails: any;
  reasonsList: any;
  report: any;
}

const initialPublicState: PublicState = {
  error: '',
  success: '',
  tokenDetails: null,
  showDetails: null,
  rtmToken: null,
  artistDetails: null,
  reasonsList: null,
  report: null
};

const publicReducer = createReducer(
  initialPublicState,


  on(GetTokenDetails, (state) => ({
    ...state,
    error: '',
    success: ''
  })),
  on(GetTokenDetailsSuccess, (state, { tokenDetails }) => ({
    ...state,
    error: '',
    success: '',
    tokenDetails: tokenDetails,
  })),
  on(GetTokenDetailsError, (state, { error }) => ({
    ...state,
    error,
    success: ''
  })),

  on(GetShowDetails, (state) => ({
    ...state,
    error: '',
    success: ''
  })),
  on(GetShowDetailsSuccess, (state, { showDetails }) => ({
    ...state,
    error: '',
    success: '',
    showDetails: showDetails,
  })),
  on(GetShowDetailsError, (state, { error }) => ({
    ...state,
    error,
    success: ''
  })),


  on(GenerateRtmToken, (state) => ({
    ...state,
    error: '',
    success: '',
    rtmToken: [],
  })),
  on(GenerateRtmTokenSuccess, (state, { rtmToken }) => ({
    ...state,
    error: '',
    success: '',
    rtmToken,
  })),

  on(GenerateRtmTokenError, (state, { error }) => ({
    ...state,
    error,
    success: '',
    rtmToken: [],
  })),

  on(GetArtistDetails, (state) => ({
    ...state,
    error: '',
    success: ''
  })),
  on(GetArtistDetailsSuccess, (state, { artistDetails }) => ({
    ...state,
    error: '',
    success: '',
    artistDetails: artistDetails,
  })),
  on(GetArtistDetailsError, (state, { error }) => ({
    ...state,
    error,
    success: ''
  })),

  on(GetReasons, (state) => ({
    ...state,
    error: '',
    success: ''
  })),
  on(GetReasonsSuccess, (state, { reasonsList }) => ({
    ...state,
    error: '',
    success: '',
    reasonsList: reasonsList,
  })),
  on(GetReasonsError, (state, { error }) => ({
    ...state,
    error,
    success: ''
  })),

  on(AddReport, (state) => ({ ...state, error: '', success: '' })),
  on(AddReportSuccess, (state, { report }) => ({
    ...state,
    error: '',
    success: 'Report sent successfully',
    report,
  })),
  on(AddReportError, (state, { error }) => ({
    ...state,
    error,
    success: '',
  })),

  on(ResetPublicState, (state, { params }) => {
    return {
      ...state,
      ...params,
    };
  }),
);

export function reducer(state = initialPublicState, action: Action) {
  return publicReducer(state, action);
}
